<template>
  <v-app>
    <v-main class="bg-grey-lighten-4 pa-8">
      <NuxtPage />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'Dev',
};
</script>
